import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const BannerPanel = ({ bannerImg }) => (
  <Img fluid={bannerImg.src.childImageSharp.fluid} alt={bannerImg.alt} />
)

BannerPanel.propTypes = {
  bannerImg: PropTypes.shape({
    src: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
}

export default BannerPanel
